import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import * as styles from "styles/pages/LandingStyles.module.scss"
import TaglineSection from 'components/sections/TaglineSection';
import SummarySections from 'components/sections/SummarySections';


function Landing(props) {
    return (
        <MainLayout>
            <div className={styles.landingPage}>
                <TaglineSection />
                <SummarySections />
            </div>
        </MainLayout>
    )
}

export default Landing;