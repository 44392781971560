import React from 'react';
import Button from 'react-bootstrap/Button'
import './DeepLinkButtonStyles.scss'


const DeepLinkButton = ({ className, href, buttonTarget, children }) => {

    const DEFAULT_CLASS_NAMES = "shadow " + className

    return (
        <div className="brochure-deep-link-btn brochure-bootstrap">
            <a href={href} target={buttonTarget || "_self"}>
                <Button variant="outline-dark" className={DEFAULT_CLASS_NAMES}>{children}</Button>
            </a>
        </div>
    )

}
export default DeepLinkButton;