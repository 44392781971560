import React from 'react';
import "./SummarySectionsStyles.scss";
import { GiSpeedometer } from "react-icons/gi"
import { HiOutlineCursorClick } from "react-icons/hi"
import { RiSearchEyeLine } from "react-icons/ri"
import { StoreContext } from 'store';

function SummarySectionsMobile(props) {

    const store = React.useContext(StoreContext)


    return (
        <div className="summary-sections-mobile">
            <div className="content-level2-container">
                <div className="summary-container">
                    <span className="txt-size-xl"><b>Speed</b></span>
                    <br />
                    <br />
                    <GiSpeedometer size={90} color={"rgb(54,120,254)"} />
                    <br />
                    <br />
                    
                    <span className="summary-txt">Operate 100x the current pace to determine your facility requirements</span>

                </div>
                <br />
                <span className="summary-section-border" />
                <br />
                <div className="summary-container-banding">
                    <div className="summary-container">

                        <span className="txt-size-xl"><b>Ease</b></span>
                        <br />
                        <br />
                        <HiOutlineCursorClick size={90} color={"rgb(54,120,254)"} />
                        <br />
                        <br />

                        <span className="summary-txt">Anyone can use</span>
                    </div>
                </div>
                <br />
                <span className="summary-section-border" />
                <br />
                <div className="summary-container">

                    <span className="txt-size-xl"><b>View</b></span>
                    <br />
                    <br />
                    <RiSearchEyeLine size={90} color={"rgb(54,120,254)"} />
                    <br />
                    <br />
                    <span className="summary-txt">See the many factors impacting your building requirements in one place</span>
                </div>
            </div>
        </div >
    )
}

export default SummarySectionsMobile;