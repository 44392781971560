import React from 'react'
import "styles/global/reset.css"
import "styles/global/font.css"
import "styles/global/layout.scss"
import Landing from "pages/landing.js"

const IndexPage = () => {
    return (
        <>
            <Landing/>
        </>

    )
}
export default IndexPage;