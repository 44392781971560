import React from 'react';
import "./TaglineSectionStyles.scss"
// import * as styles from "styles/pages/LandingStyles.module.scss"
import DeepLinkButton from "components/buttons/DeepLinkButton";
import screen from "images/screen.png";
import { StoreContext } from 'store';


function TaglineSection() {

    const store = React.useContext(StoreContext)


    return (
        <div className={store.isMobile ? 'tagline-section-mobile' : 'tagline-section'}>
            <div className="content-level-1-container">
                <div className="txt-column-1">
                    <div>
                        <br/>
                        
                        <text style={{ fontSize: '20px', color: store.isMobile? '#D5D5D5': null}}>Healthcare facilities are difficult to
                            determine their spatial needs and how they will perform clinically.  </text>
                        <text className="txt-line-5" style={{ fontSize: '20px', color: store.isMobile? '#faebcd': null }}>
                            Forsight determines healthcare facilities requirements quickly, so
                        </text>
                        <text className="txt-line-5" style={{ fontWeight: 'bold', fontSize: '20px', color: store.isMobile? '#faebcd': null }}> they perform the way you need now and in the of age digital health.</text>

                        <br />
                        <br />
                        <span style={{ fontSize: '20px', fontWeight: store.isMobile? 'bold': null }}>We use:</span>
                        <ul style={{ listStyleType: store.isMobile? null: "disc", paddingLeft: store.isMobile? 0: null}}>
                            <li className="txt-line-5" style={{ fontSize: '20px', color: store.isMobile? '#D5D5D5': null}}>Evidence based medicine</li>
                            <li style={{ fontSize: '20px', fontWeight: store.isMobile? 'bold': null}}>Demographics</li>
                            <li className="txt-line-5" style={{ fontSize: '20px', color: store.isMobile? '#D5D5D5': null}}>Disease incidence</li>
                            <li style={{ fontSize: '20px', fontWeight: store.isMobile? 'bold': null }}>Experience plans</li>
                            <li className="txt-line-5" style={{ fontSize: '20px', color: store.isMobile? '#D5D5D5': null}}>Spatial standards</li>
                            <li style={{ fontSize: '20px', fontWeight: store.isMobile? 'bold': null }}>Artificial Intelligence</li>
                            <li className="txt-line-5" style={{ fontSize: '20px', color: store.isMobile? '#D5D5D5': null}}>Your input generates the solution</li>
                        </ul>
                        <br />
                        <br />
                        <br />
                        <br />


                        <span className="txt-line-1">Plan <i>fast.</i>
                            <span className="txt-line-2"> Build to last.</span>
                        </span>




                        {/* <div className='learn-more-btn'>
                        <DeepLinkButton href="/uses/requirements" className="btn-size-l btn-wide learn-more-btn-bg">Learn more</DeepLinkButton>
                    </div> */}
                    </div>

                    {!store.isMobile && <div className='description-container'>


                    </div>}
                </div>

                <div className='screen-view-container'>
                    <video
                        className='screen-view-video'
                        canplay="this.play()"
                        onloadeddata="this.play();"
                        playsinline
                        id="info-video"
                        title="Video"
                        onclick="this.play()"
                        loop
                        controls="controls"
                    >
                        <source type="video/mp4" src="https://storage.googleapis.com/media-buckets-prod-forsightdigital-com/videos/FS_Screen_Overlay60.mov"></source>
                    </video>

                </div>

                {store.isMobile && <div className='description-container'>
                    <span className="desc-line-1">
                        AI driven
                        <span className="desc-line-1-part-2"> Healthcare </span>
                        delivery
                        <span className="desc-line-1-part-4"> planning
                            platform, </span> <span>defining complex building
                                requirements.
                        </span>
                    </span>

                </div>}
            </div>

        </div>
    )
}

export default TaglineSection