import React from 'react';
import "./SummarySectionsStyles.scss";
import { GiSpeedometer } from "react-icons/gi"
import { HiOutlineCursorClick } from "react-icons/hi"
import { RiSearchEyeLine } from "react-icons/ri"
import SummarySectionsMobile from "./SummarySectionsMobile"
import { StoreContext } from 'store';


function SummarySections() {

    const store = React.useContext(StoreContext)

    return (
        store.isMobile ? <SummarySectionsMobile />

            : <div className="summary-sections">
                <div className="content-level2-container">
                    <div className="summary-container">
                        <GiSpeedometer size={90} color={"rgb(54,120,254)"} />
                        <br />
                        <span className="txt-size-xl"><b>Speed</b></span>
                        <p className="txt-size-s">Operate 100x the current pace to determine your facility requirements</p>
                    </div>
                    <div className="summary-container">
                        <HiOutlineCursorClick size={90} color={"rgb(54,120,254)"} />
                        <br />
                        <span className="txt-size-xl"><b>Ease</b></span>
                        <p className="txt-size-s">Anyone can use</p>
                    </div>
                    <div className="summary-container">
                        <RiSearchEyeLine size={90} color={"rgb(54,120,254)"} />
                        <br />
                        <span className="txt-size-xl"><b>View</b></span>
                        <p className="txt-size-s">See the many factors impacting your building requirements in one place</p>
                    </div>
                </div>
            </div>
    )
}

export default SummarySections;